import _ from 'lodash';
import axios from 'axios';

import { message, Form, Image, Space, Divider, Card, Typography } from 'antd';

// const API_ORIGIN = "https://dev-api.jp-ex.io";
// // const API_ORIGIN = "https://jp-ex.io";
// const API2_ORIGIN = "https://dev-api2.jp-ex.io";
const API_ORIGIN = process.env.REACT_APP_API_URL;
const API2_ORIGIN = process.env.REACT_APP_API2_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

export async function call(_method, _endpoint, _postObj, _opts, header = {}) {
  try {
    let opts = _opts || {};

    let baseURL = API_ORIGIN;
    let withCredentials = true;

    if (opts.apiVersion === 2) {
      baseURL = API2_ORIGIN;
      withCredentials = false;
    }

    let method = _.toString(_method).toLowerCase();
    let endpoint = _.toString(_endpoint);
    let postObj = _.clone(_postObj) || {};
    let params;

    // const user = getStore().getState().app.user;
    // console.log("User Data >>> ", user);

    let headers = {
      lang: "en",
      // accesstoken: Main.getAccessToken(),
      // userid: Main.getUserId(),
      ...header
    };

    if (method === 'get') {
      params = postObj;
      postObj = null;
    }

    // console.log('API Header >>> ', headers);

    // let resp = await axios[method](endpoint, data, {headers});
    let resp = await axios({
      method,
      baseURL,
      url: endpoint,
      headers,
      // timeout: 5000,
      params, // GET
      data: postObj, // POST/PUT
      withCredentials,
    })
    let respData = resp.data;
    let {
      code, msg, success, data
    } = respData;

    console.log(`respData >> `, respData);
    // getStore().dispatch(ActionCreators.setNeedRefresh(false));

    if (!success || Number(code) <= 0) {
      console.error(`Service.call() Error :: ${code} :: ${msg}`);
      // if (errorCode === -101) {
      // TODO :: Redirect ???
      // }
      // let errorObj = {
      //   success,
      //   errorCode,
      //   errorMessage
      // }

      return respData;
    }
    // TODO :: resmove result in response
    if (data === null) {
      return respData;
    }
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
}

const API2 = {
  call: async (_method, _endpoint, _postObj, _opts = {}) => {
    return call(_method, _endpoint, _postObj, {
      apiVersion: 2,
      ..._opts,
    });
  },
};

export { API2 };


export default call;
