import React, {useEffect, useState} from 'react';
import * as Service from './Service';
import Constants from './Constants';

export default function App(props) {
    const location = window?.location;
    const params = new URLSearchParams(location?.search)
    const id = params.get('id')
    const type = params.get('type')
    let lang = params.get('lang') || 'en';
    if (!Constants.LOCALE_LIST.includes(lang)) {
      lang = 'en';
    }

    const [detailData, setDetailData] = useState(null);

    const getData = async () => {
      const postData = { id };
      const header = {};
      if (lang) {
        header.lang = lang;
      }
      let url = '/helpCenter/readDetail';
      if (type === 'help') {
        url = '/noticeCenter/readDetail';
      }
      const data = await Service.call('post', url, postData, null, header);
      setDetailData(data);
    };

    useEffect(() => {
      getData();
    }, []);

    return (
      <div style={{
        backgroundColor: '#1A222D',
        padding: '15px',
        minHeight: 'calc(100vh - 30px)'
      }}
      >
        {
          detailData && (
            <>
              <div style={{ color: '#CFD8E4', fontSize: '20px', margin: '30px 0' }}>{detailData.name}</div>
              <div style={{color: '#B1BDCE', fontSize: '12px', lineHeight: '25px' }}>
                {
                  detailData && (
                    <div
                      dangerouslySetInnerHTML = {{__html: detailData.content}}
                      className="article-container"
                    />
                  )
                }
              </div>
            </>
          )
        }
      </div>
    );
};
